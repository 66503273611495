import React from "react";

const PageHeader = ({ title, imgSrc, description = "" }) => {
  return (
    <section className="page-header">
      <div className="container">
        {imgSrc && (
          <figure>
            <img src={imgSrc} alt={title} />
          </figure>
        )}
        <div className="page-content">
          {title && <h2 className="text-uppercase">{title}</h2>}
          {description && <p className="_desc">{description}</p>}
        </div>
      </div>
    </section>
  );
};

export default PageHeader;
