import React, { useEffect, useRef, useState } from "react";
import MetaData from "../../helpers/MetaData";
import PageHeader from "../../components/PageHeader";
import { useForm } from "react-hook-form";
import ApiService from "../../API/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../../store/authSlice";
import notification from "../../helpers/notification";
import { useNavigate } from "react-router-dom";

const Login = () => {
  
  const {token} = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resErrorRef = useRef()
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const handleLogin = async (values) => {
    try {
      setLoading(true)
      const { user, token } = await ApiService.login(values);
      dispatch(setToken(token));
      localStorage.setItem("token", token);
      dispatch(setUser(user));
      notification("success", 'Logged in successfully');
      reset()
      navigate('/', {replace: true})
      setLoading(false)
      resErrorRef.current.innerText = '';
      resErrorRef.current.classList.add('d-none')
    } catch (error) {
      setLoading(false)
      console.log(error);
      resErrorRef.current.innerText = error.data.message;
      resErrorRef.current.classList.remove('d-none')
    }
  };

  useEffect(() => {
    if(token) {
      navigate('/')
    }
  },[token, navigate])

  return (
    <>
      <MetaData title="Login - ISKCON of Bhiwandi" />
      <PageHeader title="Login" />

      <section className="join-family-sec pt-0 login_page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="join-family-form">
                <form onSubmit={handleSubmit(handleLogin)}>
                  <div className="form-part">
                    <label htmlFor="email">Email ID</label>
                    <input
                      type="text"
                      placeholder="Email ID"
                      {...register("email", {
                        required: true,
                        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      })}
                    ></input>
                    {errors?.email?.type === "required" && (
                      <small className="text-danger error">
                        Email is required
                      </small>
                    )}
                    {errors?.email?.type === "pattern" && (
                      <small className="text-danger error">
                        Please provide valid Email Id
                      </small>
                    )}
                  </div>
                  <div className="form-part">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      placeholder="Password"
                      {...register("password", {
                        required: true,
                      })}
                    ></input>
                    {errors?.password?.type === "required" && (
                      <small className="text-danger error">
                        Password is required.
                      </small>
                    )}
                  </div>

                  <p className="text-center text-danger d-none" ref={resErrorRef}></p>

                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <div className="d-flex align-items-center">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          ></div>
                          <div className="ms-2">SUBMITTING...</div>
                        </div>
                      ) : (
                        <>SUBMIT</>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
