import React, { useEffect, useState } from "react";
import MetaData from "../helpers/MetaData";
import notification from "../helpers/notification";
import PageHeader from "../components/PageHeader";
import { AiOutlineCalendar } from "react-icons/ai";
import { useForm } from "react-hook-form";
import moment from "moment";
import ApiService from "../API/ApiService";
import axios from "axios";

const Home = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    setError,
    clearErrors
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [showTempleInput, setShowTempleInput] = useState(false);
  const [donationTypes, setDonationTypes] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [templeLocations, setTempleLocations] = useState([]);
  const [preview, setPreview] = useState(null);
  // const [showBirthDayInput, setShowBirthDayInput] = useState(false)
  // const [showAnniversaryInput, setShowAnniversaryInput] = useState(false)

  const templeWatch = watch("templeLocation");
  const imageWatch = watch('screenshot');
  // const typeWatch = watch('donationType')

  // Donation Submit
  const handleDonate = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();

      // formData.append("name", values.name);
      formData.append("transactionDate", values.transactionDate);
      formData.append("donorName", values.donorName);
      formData.append("mobile", values.mobile);
      formData.append("alternateNumber", values.alternateNumber);
      formData.append("transactionId", values.transactionId);
      formData.append("transactionIdScreenshot", values.screenshot[0]);
      formData.append("amount", values.amount);
      formData.append("remark", values.remark);
      formData.append("donationType", values.donationType);
      formData.append("campaign", values.campaign);
      formData.append("address", values.address);
      formData.append("state", values.state);
      formData.append("city", values.city);
      formData.append("pincode", values.pincode);
      formData.append("panNo", values.panNo);
      formData.append("email", values.email);
      formData.append("dob", values.dob);
      formData.append("doa", values.doa);
      formData.append("templeLocation", values.templeLocation);
      if (showTempleInput) {
        formData.append("otherTempleLocation", values.otherTempleLocation);
      }

      const { message } = await ApiService.SubmitDonationDetailsForm(formData);
      notification("success", message);

      reset();
      setPreview(null)
      setLoading(false);
      // setShowBirthDayInput(false)
      // setShowAnniversaryInput(false)
    } catch (error) {
      setLoading(false);
      notification("error", (error && error?.data) ? error?.data?.message : 'Unable to submit form');
    }
  };

  // Pan input validation
  const handlePanInputChange = (e) => {
    const cursorPosition = e.currentTarget.selectionStart;

    if (cursorPosition  < 5) {
      if (/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    } else if (cursorPosition  >= 5 && cursorPosition  < 9) {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    } else if (cursorPosition  >= 9 && cursorPosition  <= 10) {
      if (/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  // Option fetch
  const fetchOptions = async () => {
    try {
      const { donationTypes, campaigns, templeLocation } = await ApiService.fetchOptions();
      setDonationTypes(donationTypes)
      setCampaigns(campaigns)
      setTempleLocations(templeLocation)
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch city and state by pin
  const fetchStateCity = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;

      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);
      if(!data || data[0].Status === 'Error') {
        return notification("warning", "Enter valid PIN code!");
      };

      let cityVal = '';
      if (data[0]?.PostOffice?.length > 1) {
        data[0].PostOffice.map(ct => {
          if (ct.Block !== 'NA') {
            return cityVal = ct.Block
          }else {
            return false;
          }
        })
      } else {
        cityVal = data[0].PostOffice[0].Block;
      }

      if (data[0].Status === "Success") {
        setValue("city", cityVal !== '' ? cityVal : data[0].PostOffice[0].District);
        setValue("state", data[0].PostOffice[0].State);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const transactionChangeHandle = (e) => {
    const field = e.target.name;
    
    if(e.target.value) {
      e.target.classList.remove('_placeholder')
      const isDateValid = moment(e.target.value).isSameOrBefore(moment().format('YYYY-MM-DD'), 'day')

      switch (field) {
        case 'transactionDate': {
          if(!isDateValid) {
            setError('transactionLimit', {type: 'limit'})
          }else {
            clearErrors("transactionLimit")
          }
          break;
        }
        case 'dob': {
          if(!isDateValid) {
            setError('dobLimit', {type: 'limit'})
          }else {
            clearErrors("dobLimit")
          }
          break;
        }
        case 'doa': {
          if(!isDateValid) {
            setError('doaLimit', {type: 'limit'})
          }else {
            clearErrors("doaLimit")
          }
          break;
        }
        default : {}
      }

    }else {
      e.target.classList.add('_placeholder')
    }
  }

  // Other temple location input toggle
  useEffect(() => {
    if (templeWatch === "Others") {
      setShowTempleInput(true);
    } else {
      setShowTempleInput(false);
    }
  }, [templeWatch]);

  // Upload media handle
  useEffect(() => {
    if(imageWatch && imageWatch?.length > 0) {
      if(imageWatch[0].type?.startsWith('image/')){
        const reader = new FileReader();
        reader.onload = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(imageWatch[0]);
      }else if(imageWatch[0].type?.startsWith('application/')){
        setPreview(null);
        const downloadLink = URL.createObjectURL(imageWatch[0]);
        setPreview(downloadLink);
      }else {
        setPreview(null);
      }
    }
  },[imageWatch])

  // useEffect(() => {
  //   if(typeWatch) {
  //     const type = donationTypes.find(ty => ty._id === typeWatch)
  //     if(type.name === 'Birthday'){
  //       setShowBirthDayInput(true)
  //     }else {
  //       setShowBirthDayInput(false)
  //     }

  //     if(type.name === 'Anniversary'){
  //       setShowAnniversaryInput(true)
  //     }else {
  //       setShowAnniversaryInput(false)
  //     }
  //   }
  // },[typeWatch, donationTypes])

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <>
      <MetaData title="Donation Form - ISKCON of Bhiwandi" />
      <PageHeader title="Quick Donation" />

      <section className="join-family-sec pt-0">
        <div className="container">
          <div className="join-family-form">
            <form onSubmit={handleSubmit(handleDonate)}>
              <div className="row">

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="transactionDate" className="required-field">
                      Transaction Date
                    </label>
                    <div className="input-date">
                      <input
                        type="date"
                        className="_placeholder"
                        placeholder="Transaction Date"
                        {...register("transactionDate", {
                          required: true,
                          onChange: transactionChangeHandle
                        })}
                        max={moment(Date.now()).format("YYYY-MM-DD")}
                      ></input>
                      <AiOutlineCalendar />
                      {errors.transactionDate?.type === "required" && (
                        <small className="text-danger">
                          Transaction Date is required{" "}
                        </small>
                      )}
                      {errors.transactionLimit?.type === "limit" && (
                        <small className="text-danger">
                          Please provide valid date
                        </small>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="donorName" className="required-field">
                      Donor Name
                    </label>
                    <input
                      type="text"
                      placeholder="Donor Name"
                      {...register("donorName", {
                        required: true,
                      })}
                    ></input>
                    {errors.donorName?.type === "required" && (
                      <small className="text-danger">
                        Donor Name is required{" "}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="mobile" className="required-field">
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      placeholder="Mobile"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("mobile", {
                        required: true,
                        pattern: {
                          value: /^[6-9]\d*$/,
                          message: "Please provide valid phone number",
                        },
                        minLength: {
                          value: 10,
                          message: "Phone number must be minimum 10 digit",
                        },
                      })}
                    />
                    {errors.mobile?.type === "required" && (
                      <small className="text-danger error">
                        Mobile is required
                      </small>
                    )}
                    {errors.mobile && (
                      <small className="text-danger error">
                        {errors.mobile.message}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="alternateNumber">Alternate Mobile</label>
                    <input
                      type="tel"
                      placeholder="Alternate Mobile"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("alternateNumber", {
                        pattern: {
                          value: /^[6-9]\d*$/,
                          message: "Please provide valid phone number",
                        },
                        minLength: {
                          value: 10,
                          message: "Phone number must be minimum 10 digit",
                        },
                      })}
                    />
                    {errors.alternateNumber && (
                      <small className="text-danger error">
                        {errors.alternateNumber.message}
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="transactionId" className="required-field">
                      Transaction Id
                    </label>
                    <input
                      type="text"
                      placeholder="Transaction Id"
                      {...register("transactionId", {
                        required: true,
                      })}
                    ></input>
                    {errors.transactionId?.type === "required" && (
                      <small className="text-danger error">
                        Transaction Id is required
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="amount" className="required-field">
                      Amount
                    </label>
                    <input
                      type="text"
                      placeholder="Amount"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("amount", {
                        required: true,
                        pattern: /^-?\d+(\.\d+)?$/,
                        min: 1
                      })}
                    />
                    {errors.amount?.type === "required" && (
                      <small className="text-danger error">
                        Amount is required
                      </small>
                    )}
                    {errors.amount?.type === "pattern" && (
                      <small className="text-danger error">
                        Please provide valid amount
                      </small>
                    )}
                    {errors.amount?.type === "min" && (
                      <small className="text-danger error">
                        Amount must be greater than 0
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-8 col-md-6">
                  <div className="form-part">
                    <label htmlFor="remark">Remark</label>
                    <input
                      type="text"
                      placeholder="Remark"
                      {...register("remark")}
                    ></input>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="donationType" className="required-field">
                      Donation Type
                    </label>
                    <select
                      {...register("donationType", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Donation Type ---</option>
                      {donationTypes?.map((type, i) => (
                        <option key={i} value={type._id}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                    {errors.donationType?.type === "required" && (
                      <small className="text-danger error">
                        Donation Type is required
                      </small>
                    )}
                  </div>
                </div>

                  <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="dob">
                    Date of Birth
                    </label>
                    <div className="input-date">
                      <input
                        type="date"
                        className="_placeholder"
                        placeholder="Date of Birth"
                        {...register("dob", {
                          onChange: transactionChangeHandle
                        })}
                        max={moment(Date.now()).format("YYYY-MM-DD")}
                      ></input>
                      <AiOutlineCalendar />
                      {errors.dobLimit?.type === "limit" && (
                        <small className="text-danger">
                          Please provide valid date
                        </small>
                      )}
                    </div>
                  </div>

                </div>
                {/* {
                  showBirthDayInput &&
                } */}

                  <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="doa">Date of Anniversary</label>
                    <div className="input-date">
                      <input 
                        type="date"
                        className="_placeholder"
                        placeholder="Date of Anniversary"
                        {...register('doa',{
                          onChange: transactionChangeHandle
                        })}
                        max={moment(Date.now()).format("YYYY-MM-DD")}
                      />
                        <AiOutlineCalendar />
                        {errors.doaLimit?.type === "limit" && (
                          <small className="text-danger">
                            Please provide valid date
                          </small>
                        )}
                    </div>
                  </div>
                </div>
                {/* {
                  showAnniversaryInput &&
                } */}

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="campaign" className="required-field">
                      Campaign
                    </label>
                    <select
                      {...register("campaign", {
                        required: true,
                      })}
                    >
                      <option value="">--- Select Campaign ---</option>
                      {
                        campaigns?.map(campaign => (
                          <option value={campaign?._id} key={campaign?._id}>{campaign?.name}</option>
                        ))
                      }
                    </select>
                    {errors.campaign?.type === "required" && (
                      <small className="text-danger error">
                        Campaign is required
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="pincode">Pin Code</label>
                    <input
                      type="text"
                      placeholder="Pin code"
                      {...register("pincode", {
                        pattern: /^-?\d+(\.\d+)?$/,
                        onChange: e => {
                          setValue("state", "");
                          setValue("city", "");
                          if (e.target.value.length === 6) {
                            fetchStateCity(e.target.value);
                          }
                        }
                      })}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={6}
                      minLength={6}
                    ></input>
                    {errors?.pincode?.type === "pattern" && (
                      <small className="text-danger error">
                        Please provide valid Pin code
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="state">State</label>
                    <input
                      type="text"
                      placeholder="State"
                      {...register("state")}
                    ></input>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      placeholder="City"
                      {...register("city")}
                    ></input>
                  </div>
                </div>
             
                <div className="col-lg-8 col-md-6">
                  <div className="form-part">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      placeholder="Address"
                      {...register("address")}
                    ></input>
                  </div>
                </div>
               
                <div className="col-lg-4 col-md-6">
                  <div className="form-part">
                    <label htmlFor="panNo">PAN Card</label>
                    <input
                      type="text"
                      className="text-uppercase"
                      placeholder="PAN Card"
                      {...register("panNo", {
                        pattern: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                      })}
                      maxLength={10}
                      onKeyPress={handlePanInputChange}
                    ></input>
                    {errors?.panNo?.type === "pattern" && (
                      <small className="text-danger error">
                        Please provide valid PAN
                      </small>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12 col-md-6">
                          <div className="form-part">
                            <label htmlFor="email">Email ID</label>
                            <input
                              type="text"
                              placeholder="Email ID"
                              {...register("email", {
                                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                              })}
                            ></input>
                            {errors?.email?.type === "pattern" && (
                              <small className="text-danger error">
                                Please provide valid Email Id
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-6">
                            <div className="form-part">
                            <label htmlFor="templeLocation" className="required-field">
                              Temple Location
                            </label>
                            <select
                              {...register("templeLocation", {
                                required: true,
                              })}
                            >
                              <option value="">--- Select Template Location ---</option>
                              {
                                templeLocations?.map(templeLocation => (
                                  <option value={templeLocation?._id} key={templeLocation?._id}>{templeLocation?.name}</option>
                                ))
                              }
                              <option value="Others">Others</option>
                            </select>
                            {errors.templeLocation?.type === "required" && (
                              <small className="text-danger error">
                                Temple Location is required
                              </small>
                            )}
                          </div>
                        </div>
                        {showTempleInput && (
                          <div className="col-lg-12 col-md-6">
                            <div className="form-part">
                              <label htmlFor="otherTempleLocation">
                                Temple Location
                              </label>
                              <input
                                type="text"
                                placeholder="Temple Location"
                                {...register("otherTempleLocation", {
                                  required: true,
                                })}
                              ></input>
                              {errors.otherTempleLocation?.type === "required" && (
                                <small className="text-danger error">
                                  Temple Location is required
                                </small>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-md-6">
                            <p className="mb-0 label-text">
                              <span>Transaction Id Screenshot</span>
                            </p>
                          <div className="custom-uploads">
                            <p>
                              {
                                (imageWatch && imageWatch.length > 0) ?
                                imageWatch[0].name
                                :
                                "Drag or Click to upload image"
                              }
                            </p>
                            <label htmlFor="screenshot">
                              <input
                                className="form-control"
                                type="file"
                                placeholder="Transaction Id Screenshot"
                                {...register("screenshot", {
                                  required: true,
                                })}
                                accept="image/jpg, image/jpeg, image/png, image/webp, application/pdf"
                              />
                            </label>
                            <small>Accepted file .jpg, .jpeg, .png, .webp, .pdf</small>
                            {errors.screenshot?.type === "required" && (
                              <small className="text-danger error">
                                Screenshot is required
                              </small>
                            )}
                          </div>
                          
                        </div>
                          
                        <div className="col-md-6">
                          <p className="label-text mb-0">
                              Selected File
                          </p>
                          <div className="preview-wrap">
                              {
                              preview &&
                              <>
                              {
                                preview?.includes('image/') ?
                                <figure className="mb-0">
                                  <img src={preview} alt="" />
                                </figure>
                                  :
                                <a href={preview} rel="noreferrer" target="_blank">
                                  <figure className="mb-0">
                                    <img src="/images/pdf-placeholder.png" alt="" />
                                  </figure>
                                </a>
                              }
                              </>  
                              }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-sm-4 mt-2">
                  <p className="text-danger mb-0 text-center" style={{fontSize: '14px'}}>
                    * Indicates required fields
                  </p>
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <div className="d-flex align-items-center">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          ></div>
                          <div className="ms-2">DONATING...</div>
                        </div>
                      ) : (
                        <>SUBMIT</>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
