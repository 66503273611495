import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Footer = () => {
  return (
    <>
      <footer className="py-4">
        <div className="container">
          <p className="mb-0 text-center">
            &copy; {new Date().getFullYear()} ISKCON of Bhiwandi{" "}
            <span className="d-inline-block mx-2">|</span> All Rights Reserved.
          </p>
        </div>
      </footer>
      <ToastContainer />
    </>
  );
};

export default Footer;
