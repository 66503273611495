import axios from "axios";
import history from "../helpers/history";

// Config
const fetch = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api/",
  timeout: 60000,
});

fetch.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  if (!token && !config.headers["public-request"]) {
    history.push("/");
    window.location.reload();
  }

  return config;
});

fetch.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

const ApiService = {};

ApiService.SubmitDonationDetailsForm = (data) => {
  return fetch({
    url: "/donation-details",
    method: "POST",
    data,
  });
};

ApiService.fetchOptions = () => {
  return fetch({
    url: "/donation-details/options",
    method: "GET",
  });
};

ApiService.login = (data) => {
  return fetch({
    url: "/auth/login",
    method: "POST",
    data,
    headers: {
      "public-request": "true",
    },
  });
};

export default ApiService;
