import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaYoutube, FaInstagram } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/authSlice";
import notification from "../helpers/notification";

const Header = () => {

  const {token} = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout());
    notification('success', 'Logged out successfully')
  }

  return (
    <header>
      <div className="container">
        <div className="top-nav">
          <ul className="login-wrap list-unstyled mb-0">
            <li className="me-lg-2">
              <a href={process.env.REACT_APP_MAIN_WEBSITE_URL} target="_blank" rel="noreferrer" className="custom-btn-cls box-hover">
                Visit Website
              </a>
            </li>
            {
              token &&
              <li className="">
                  <a href={`${process.env.REACT_APP_BACKEND_URL}/admin`} target="_blank" rel="noreferrer" className="custom-btn-cls box-hover m-0">
                    Dashboard
                  </a>
              </li>
              }
          </ul>

          <span className="logo">
            <Link to="/">
              <img src="/images/logo.png" alt="" />
            </Link>
          </span>

          <ul className="social-icons list-unstyled mb-0">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://facebook.com/iskconbhiwandi"
              >
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/@ISKCONATTAPUR "
              >
                <FaYoutube />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/iskconbhiwandi"
              >
                <FaInstagram />
              </a>
            </li>
            <li className="header-btn">
                {
                  token ?
                  <button onClick={handleLogout}>
                    Logout
                  </button>
                  :
                  <Link to="/login">
                    Login
                  </Link>
                }
            </li>
          </ul>
        </div>
        <div className="bottom-nav">
          <ul className="list-unstyled d-flex align-items-center justify-content-center mb-0">
              <li>
                <a href={process.env.REACT_APP_MAIN_WEBSITE_URL} target="_blank" rel="noreferrer">
                  Visit Website
                </a>
              </li>
              <li>
                {
                  token &&
                <a href={`${process.env.REACT_APP_BACKEND_URL}/admin`} target="_blank" rel="noreferrer">
                  Dashboard
                </a>
                }
              </li>
              <li>
              {
                  token ?
                  <button onClick={handleLogout}>
                    Logout
                  </button>
                  :
                  <Link to="/login">
                    Login
                  </Link>
                }
              </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
