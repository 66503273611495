import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./css/style.scss";
import { HelmetProvider } from "react-helmet-async";
import Home from './pages/Home'
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './pages/Auth/Login';
import { Provider } from "react-redux";
import store from "./store/store";
import ProtectedRoute from './middleware/ProtectedRoute';
import ErrorPage from './components/ErrorPage.jsx';

const App = () => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <Router>
          <Header/>
          <Routes>
            <Route exact path="/" element={<ProtectedRoute><Home /></ProtectedRoute>}></Route>
            <Route exact path="/login" element={<Login />}></Route>

            <Route exact path="*" element={<ProtectedRoute><ErrorPage/></ProtectedRoute>}></Route>
          </Routes>
          <Footer/>
        </Router>
      </HelmetProvider>
    </Provider>
  )
}

export default App