import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiService from "../API/ApiService";
import history from "../helpers/history";


const STATUS = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

export const fetchUser = createAsyncThunk("user/fetch", async () => {
  try {
    const { user } = await ApiService.fetchUser();
    return user;
  } catch (error) {
    console.log(error);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token"),
    user: null,
    status: STATUS.SUCCESS,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    logout(state, action) {
      localStorage.removeItem("token");
      state.user = null;
      state.token = null;
      history.push("/");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = STATUS.ERROR;
      });
  },
});

export const { setUser, setToken, logout } = authSlice.actions;

export default authSlice.reducer;
